import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import ParallelTextHistory from './ParallelTextHistory';
import { useLogger } from '../hooks/useLogger';
import './css/ParallelTextViewer.css';
import Toolbar from './Toolbar';
import Menu from './Menu';
import './css/common.css';
import './css/themes.css';
import { useTranslation } from 'react-i18next';
import { apiRequest, isAdminMode as checkAdminMode } from '../config/api.js';
import i18n from '../i18n';
// import WordDetailsModal from './WordDetailsModal';
import ThemePickerWelcomeScreen from './ThemePickerWelcomeScreen';
import { useLocation, useNavigate } from 'react-router-dom';
import LoadingProgress from './LoadingProgress';
import ReactDOM from 'react-dom';
import AdminToolbar from './AdminToolbar';
import FeedbackModal from './FeedbackModal';

// Add this new component at the top level, outside of ParallelTextViewer
const LoadingDots = ({ direction }) => {
  const [dots, setDots] = useState('');

  useEffect(() => {
    const interval = setInterval(() => {
      setDots(prev => {
        if (direction === 'rtl') {
          // RTL animation
          switch (prev) {
            case '': return '.';
            case '.': return '..';
            case '..': return '...';
            default: return '';
          }
        } else {
          // LTR animation
          switch (prev) {
            case '': return '.';
            case '.': return '..';
            case '..': return '...';
            default: return '';
          }
        }
      });
    }, 500);

    return () => clearInterval(interval);
  }, [direction]);

  return <span className="loading-dots">{dots}</span>;
};

const InteractiveWelcomePrompt = ({ onMenuOpen }) => {
  const { t } = useTranslation();
  const clickableText = t('welcome.clickableText');
  
  // Split the translation string and insert the clickable element
  const welcomeText = t('welcome.chooseBook', { clickableText: '{{clickableText}}' });
  const [before, after] = welcomeText.split('{{clickableText}}');
  
  return (
    <div className="choose-book-prompt">
      <span>
        {before}
        <span 
          className="clickable-welcome-text"
          onClick={onMenuOpen}
          role="button"
          tabIndex={0}
        >
          {clickableText}
        </span>
        {after}
      </span>
    </div>
  );
};

const ParallelTextViewer = ({ 
  languageId, 
  onLanguageChange, 
  openCookiePreferences, 
  magicLinkStatus, 
  shouldTriggerSubscribe, 
  onSubscribeTriggered,
  userProfile,
  isLoadingProfile,
  onRefetchProfile
}) => {
  const { t } = useTranslation();

  const [bookId, setBookId] = useState('');
  const [bookDetails, setBookDetails] = useState(null);
  const [currentPageId, setCurrentPageId] = useState(null);
  const [currentPage, setCurrentPage] = useState("");
  const [pageContent, setPageContent] = useState(null);
  const [pagePronunciation, setPagePronunciation] = useState(null);
  const [pageFurigana, setPageFurigana] = useState(null);
  const [showFurigana, setShowFurigana] = useState(true);
  const [error, setError] = useState('');
  const [menuOpen, setMenuOpen] = useState(true);
  const [forceShowBooks, setForceShowBooks] = useState(false);
  const [hoveredWord, setHoveredWord] = useState(null);

  const [textAlignment, setTextAlignment] = useState(() => {
    // Default to 'justify-center' if not in localStorage
    return localStorage.getItem('textAlignment') || 'justify-center';
  });
  const [dyslexicFontEnabled, setDyslexicFontEnabled] = useState(() => {
    return localStorage.getItem('dyslexicFontEnabled') === 'true';
  });
  const [nativeLanguage, setNativeLanguage] = useState(() => {
    const params = new URLSearchParams(window.location.search);
    return params.get('n') || localStorage.getItem('nativeLanguage') || "";
  });
  const [learningLanguage, setLearningLanguage] = useState(() => {
    const params = new URLSearchParams(window.location.search);
    return params.get('l') || localStorage.getItem('learningLanguage') || "";
  });
  const [nativeLanguageDirection, setNativeLanguageDirection] = useState(() => {
    return localStorage.getItem('nativeLanguageDirection') || 'ltr';
  });
  const [learningLanguageDirection, setLearningLanguageDirection] = useState(() => {
    return localStorage.getItem('learningLanguageDirection') || 'ltr';
  });

  const [isLoadingBook, setIsLoadingBook] = useState(false);
  const [isLoadingPage, setIsLoadingPage] = useState(false);
  const abortControllerRef = useRef(null);
  const [showNativeLanguage, setShowNativeLanguage] = useState(true);
  const [showPhonetics, setShowPronunciation] = useState(true);
  const [pageAssociations, setPageAssociations] = useState(null);
  const [showThemePicker, setShowThemePicker] = useState(false);
  const [currentTheme, setCurrentTheme] = useState('Classic');
  const [themeLoaded, setThemeLoaded] = useState(false);

  const [selectedWord, setSelectedWord] = useState(null);
  // const [isWordDetailsModalOpen, setIsWordDetailsModalOpen] = useState(false);
  const [selectedWordDetails, setSelectedWordDetails] = useState(null);

  const [pageNumberInput, setPageNumberInput] = useState('');
  const [isPageNumberChanged, setIsPageNumberChanged] = useState(false);

  const logger = useLogger('ParallelTextViewer');

  const location = useLocation();
  const navigate = useNavigate();

  const [validLanguageOptions, setValidLanguageOptions] = useState([]);

  const [urlParamsProcessed, setUrlParamsProcessed] = useState(false);

  const [initialPageId, setInitialPageId] = useState(null);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const [nativeContent, setNativeContent] = useState(null);
  const [learningContent, setLearningContent] = useState(null);
  const [associations, setAssociations] = useState(null);

  const [loadingProgress, setLoadingProgress] = useState(0);
  const [loadingLabel, setLoadingLabel] = useState('');

  const [isProductPromotionModalOpen, setIsProductPromotionModalOpen] = useState(false);

  const [bookmarks, setBookmarks] = useState([]);
  const [currentBookmark, setCurrentBookmark] = useState(null);

  const isPremiumUser = userProfile?.subscription_tier === 'premium';

  const [textSize, setTextSize] = useState(() => {
    return parseInt(localStorage.getItem('textSize')) || 100;
  });

  const [currentChapterId, setCurrentChapterId] = useState(null);

  const [isAdminMode, setIsAdminMode] = useState(false);
  const [nativeContentPath, setNativeContentPath] = useState(null);

  // Add new settings state
  const [loadHighlighting, setLoadHighlighting] = useState(true);
  const [loadPronunciation, setLoadPronunciation] = useState(true);
  const [isNavigationPending, setIsNavigationPending] = useState(false);
  const [pendingNavigation, setPendingNavigation] = useState(null);

  // Add new state for user settings
  const [userSettings, setUserSettings] = useState({
    dyslexicFontEnabled: false,
    loadHighlighting: true,
    loadPronunciation: true,
    textAlignment: 'justify-center'
  });

  // Add new state to track if settings are loaded
  const [settingsLoaded, setSettingsLoaded] = useState(false);

  // Add new state variables after the existing state declarations
  const [chaptersData, setChaptersData] = useState(null);

  // Add state to track last click time
  const [lastNavigationTime, setLastNavigationTime] = useState(0);
  const DEBOUNCE_DELAY = 3000; // 1 second delay between clicks

  // Add state to track if buttons are debounced
  const [isDebounced, setIsDebounced] = useState(false);

  // Add new state for feedback modal
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [hasOpenedBook, setHasOpenedBook] = useState(false);
  // Add state for tracking if user has given feedback
  const [hasFeedbackBeenGiven, setHasFeedbackBeenGiven] = useState(() => {
    return localStorage.getItem('hasFeedbackBeenGiven') === 'true';
  });

  // Add new state for chapters loading
  const [isLoadingChapters, setIsLoadingChapters] = useState(true);

  // Add new state to better reflect its purpose
  const [changeToBooks, setChangeToBooks] = useState(false);

  // Add fetchChapters function before fetchPage
  const fetchChapters = useCallback(async () => {
    if (!bookId) return;
    
    setIsLoadingChapters(true);
    try {
      const isPremiumUser = userProfile?.subscription_tier === 'premium';
      const languageToUse = isPremiumUser ? learningLanguage : nativeLanguage;
      
      const response = await apiRequest('GET', 'BOOK_CHAPTERS', 
        { book_id: bookId },
        { language: languageToUse }
      );
      setChaptersData(response);
    } catch (error) {
      logger.error('Error fetching chapters:', error);
    } finally {
      setIsLoadingChapters(false);
    }
  }, [bookId, userProfile, learningLanguage, nativeLanguage]);
  // Add these two functions near the top of the component
  const resetPageStates = useCallback(() => {
    ReactDOM.unstable_batchedUpdates(() => {
      // Content states
      setNativeContent(null);
      setLearningContent(null);
      setAssociations(null);
      setPagePronunciation(null);
      setPageFurigana(null);
      
      // Page-specific states
      setCurrentPage('');
      setCurrentPageId(null);
      setPageNumberInput('');
      setIsPageNumberChanged(false);
      setPendingNavigation(null);
      setIsNavigationPending(false);
      
      // UI states for page
      setError('');
      setLoadingProgress(0);
      setLoadingLabel('');
      setHoveredWord(null);
      setSelectedWordDetails(null);
    });
  }, []);

  const resetBookStates = useCallback(() => {
    ReactDOM.unstable_batchedUpdates(() => {
      // Reset all page states first
      resetPageStates();
      
      // Additional book-level states
      setChaptersData(null);
      setCurrentChapterId(null);
      setBookDetails(null);
      setMenuOpen(false);
    });
  }, [resetPageStates]);

  // Update the useEffect that handles book and page loading
  useEffect(() => {
    let isMounted = true;
    
    if (bookId && urlParamsProcessed && settingsLoaded) {
      resetBookStates();
      
      if (isMounted) {
        const loadInitialContent = async () => {
          // First load the book and chapters
          await loadBook();
          await fetchChapters();
          
          // Then load the page
          if (initialPageId) {
            await fetchPage(initialPageId);
            setInitialPageId(null);
          }
        };
        
        loadInitialContent();
      }
    }
    
    return () => {
      isMounted = false;
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, [bookId, userProfile, learningLanguage, nativeLanguage, urlParamsProcessed, settingsLoaded, resetBookStates]);

  // Add a new effect to handle chapter ID updates
  useEffect(() => {
    if (currentChapterId && !chaptersData) {
      fetchChapters();
    }
  }, [currentChapterId, chaptersData, fetchChapters]);

  // Use resetPageStates when loading a new page
  const fetchPage = async (pageId) => {
    if (!bookId || !pageId) {
      logger.error('fetchPage called with invalid parameters:', { bookId, pageId });
      return;
    }

    logger.info('fetchPage called with:', { bookId, pageId });

    if (!isLoadingPage) {
      setError('');
    }

    resetPageStates();
    
    abortControllerRef.current = new AbortController();

    try {
      setIsLoadingPage(true);
      const cdnDomain = process.env.REACT_APP_CDN_SUBDOMAIN;
      const artificialDelay = 150;

      // Ensure chapters are loaded before proceeding
      if (!chaptersData) {
        await fetchChapters();
      }

      // Stage 1: Get initial responses with content paths
      const nativeResponse = await apiRequest(
        'GET',
        'BOOK_PAGE',
        { book_id: bookId, page_id: pageId },
        { native_language: nativeLanguage },
        null,
        abortControllerRef.current.signal
      );

      // Update currentChapterId from the response
      if (nativeResponse.chapter_id) {
        setCurrentChapterId(nativeResponse.chapter_id);
      }

      // Stage 2: Fetch native content from CDN
      await new Promise(resolve => setTimeout(resolve, artificialDelay));
      const nativeContent = await fetch(`https://${cdnDomain}/${nativeResponse.content_path}`)
        .then(res => {
          if (!res.ok) throw new Error(`Native content fetch failed: ${res.status}`);
          return res.text();
        })
        .then(text => JSON.parse(text));

      setNativeContent(nativeContent.content);
      setCurrentPage(nativeResponse.page_number);
      setCurrentPageId(pageId);
      setNativeContentPath(nativeResponse.content_path);

      if (learningLanguage) {
        const isPremiumEnabled = process.env.REACT_APP_DISABLE_PREMIUM_GATES === 'true' || 
                               (userProfile?.subscription_tier === 'premium');

        if (isPremiumEnabled) {
          // Fetch learning content
          const learningResponse = await apiRequest(
            'GET',
            'BOOK_PAGE',
            { book_id: bookId, page_id: pageId },
            { learning_language: learningLanguage },
            null,
            abortControllerRef.current.signal
          );

          // Add delay before fetching learning content
          await new Promise(resolve => setTimeout(resolve, artificialDelay));
          const learningContentData = await fetch(`https://${cdnDomain}/${learningResponse.content_path}`)
            .then(res => {
              if (!res.ok) throw new Error(`Learning content fetch failed: ${res.status}`);
              return res.text();
            })
            .then(text => JSON.parse(text));

          setLearningContent(learningContentData.content);

          // Only fetch associations if highlighting is enabled in user settings
          if (loadHighlighting) {
            const associationsResponse = await apiRequest(
              'GET',
              'BOOK_PAGE_ASSOCIATIONS',
              { book_id: bookId, page_id: pageId },
              { native_language: nativeLanguage, learning_language: learningLanguage },
              null,
              abortControllerRef.current.signal
            );

            await new Promise(resolve => setTimeout(resolve, artificialDelay));
            const associationsData = await fetch(`https://${cdnDomain}/${associationsResponse.association_path}`)
              .then(res => {
                if (!res.ok) throw new Error(`Associations fetch failed: ${res.status}`);
                return res.text();
              })
              .then(text => JSON.parse(text));

            setAssociations(associationsData);
          } else {
            // Clear associations if highlighting is disabled
            setAssociations(null);
          }

          // Only fetch pronunciation if enabled in settings
          if (loadPronunciation) {
            const pronunciationResponse = await apiRequest(
              'GET',
              'BOOK_PAGE_PRONUNCIATION',
              { book_id: bookId, page_id: pageId },
              { native_language: nativeLanguage, learning_language: learningLanguage },
              null,
              abortControllerRef.current.signal
            );

            // Add delay before fetching pronunciation
            await new Promise(resolve => setTimeout(resolve, artificialDelay));
            const pronunciation = await fetch(`https://${cdnDomain}/${pronunciationResponse.path}`)
              .then(res => {
                if (!res.ok) throw new Error(`Pronunciation fetch failed: ${res.status}`);
                return res.text();
              })
              .then(text => JSON.parse(text));

            setPagePronunciation(pronunciation);

            if (learningLanguage === 'ja') {
              const furiganaResponse = await apiRequest(
                'GET',
                'FURIGANA_TRANSCRIPTION',
                { book_id: bookId, page_id: pageId },
                { learning_language: learningLanguage }
              );

              // Add delay before fetching furigana
              await new Promise(resolve => setTimeout(resolve, artificialDelay));
              const furigana = await fetch(`https://${cdnDomain}/${furiganaResponse.path}`)
                .then(res => {
                  if (!res.ok) throw new Error(`Furigana fetch failed: ${res.status}`);
                  return res.text();
                })
                .then(text => JSON.parse(text));

              setPageFurigana(furigana);
            }
          } else {
            // Clear pronunciation and furigana if disabled
            setPagePronunciation(null);
            setPageFurigana(null);
          }
        }
      }

      if (!isInitialLoad) {
        const params = new URLSearchParams(window.location.search);
        if (bookId) params.set('b', bookId);
        if (nativeLanguage) params.set('n', nativeLanguage);
        if (learningLanguage) params.set('l', learningLanguage);
        params.set('p', pageId);
        navigate(`?${params.toString()}`, { replace: true });
      }

    } catch (error) {
      // Only set error if we're not in the middle of another operation
      if (!abortControllerRef.current.signal.aborted) {
        logger.error('Error fetching page:', error);
        setError(t('error.fetchPage'));
      }
    } finally {
      if (!abortControllerRef.current.signal.aborted) {
        setIsLoadingPage(false);
      }
    }
  };
  
  const loadBookPage = useCallback(async (bookId, pageId) => {
    logger.info('loadBookPage called with:', { bookId, pageId, currentBookId: bookId });

    // Validate inputs
    if (!bookId || !pageId) {
      logger.error('Invalid bookId or pageId:', { bookId, pageId });
      return;
    }

    // Compare with current bookId from state
    if (bookId === bookId) {
      logger.info('Book already loaded, fetching new page:', pageId);
      await fetchPage(pageId);
    } else {
      // Otherwise, set both book and initial page
      logger.info('Loading new book and page:', { bookId, pageId });
      setInitialPageId(pageId);
      setBookId(bookId);
    }
  }, [bookId, fetchPage]);

  const getURLWithParams = useCallback((path) => {
    const params = new URLSearchParams(location.search);
    return `${path}${params.toString() ? '?' + params.toString() : ''}`;
  }, [location.search]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const nLang = params.get('n');
    const lLang = params.get('l');
    const bId = params.get('b');
    const pId = params.get('p');

    // Batch state updates to prevent multiple re-renders
    ReactDOM.unstable_batchedUpdates(() => {
      if (nLang) setNativeLanguage(nLang);
      if (lLang && (nLang !== lLang)) {
        if (!userProfile || userProfile.subscription_tier !== 'premium') {
          params.delete('l');
          navigate(`?${params.toString()}`, { replace: true });
          setIsProductPromotionModalOpen(true);
        } else {
          setLearningLanguage(lLang);
        }
      }
      // Only set book and page ID if we haven't processed URL params yet
      if (!urlParamsProcessed) {
        if (bId) setBookId(bId);
        if (pId) {
          setInitialPageId(pId);
          setCurrentPageId(pId);
        }
      }
      setUrlParamsProcessed(true);
    });
  }, [validLanguageOptions, userProfile]);

  useEffect(() => {
    if (urlParamsProcessed && !isInitialLoad) {
      const params = new URLSearchParams(window.location.search);
      if (nativeLanguage) params.set('n', nativeLanguage);
      if (learningLanguage) params.set('l', learningLanguage);
      if (bookId) params.set('b', bookId);
      if (currentPageId) params.set('p', currentPageId);
      const newUrl = `?${params.toString()}`;
      if (newUrl !== window.location.search) {
        navigate(newUrl, { replace: true });
      }
    }
  }, [nativeLanguage, learningLanguage, bookId, currentPageId, urlParamsProcessed, isInitialLoad]);

  const handleLanguageOptionsChange = (options) => {
    logger.info('Language options:', options);
    if (options && Object.keys(options).length > 0) {
      const params = new URLSearchParams(location.search);
      const nLang = params.get('n');
      const lLang = params.get('l');
      const bId = params.get('b');
      const pId = params.get('p');

      logger.info('Params: Native language:', nLang);
      logger.info('Params: Learning language:', lLang);
      logger.info('Params: Book ID:', bId);
      logger.info('Params: Page ID:', pId);

      let nativeLanguageChanged = false;
      let learningLanguageChanged = false;
    
      if (nLang && options[nLang]) {
        setNativeLanguage(nLang);
        setNativeLanguageDirection(options[nLang].direction);
        nativeLanguageChanged = true;
      }
      if (lLang && options[lLang]) {
        setLearningLanguage(lLang);
        setLearningLanguageDirection(options[lLang].direction);
        learningLanguageChanged = true;
      }
      if (bId) {
        setBookId(bId);
      }
      if (pId && bId && (nativeLanguageChanged || learningLanguageChanged)) {
        fetchPage(pId);
      }
    
      setValidLanguageOptions(options);
    }
  }

  const handleLogin = () => {
    logger.info('User logged in');
    onRefetchProfile();
  };

  const handleSignup = () => {
    logger.info('User signed up');
    onRefetchProfile();
  };

  const handleLogout = () => {
    logger.info('User logged out');
    onRefetchProfile();
  };

  const handlePasswordReset = () => {
    logger.info('Password reset');
    onRefetchProfile();
  };

  const ClickableText = ({ text, onWordClick, language }) => {
    const words = text.split(' ');
    return (
      <span className="clickable-text">
        {words.map((word, index) => (
          <span
            key={index}
            className="hoverable-word"
            onClick={() => onWordClick(word)}
          >
            {word}
            {index < words.length - 1 ? ' ' : ''}
          </span>
        ))}
      </span>
    );
  };

  useEffect(() => {
    localStorage.setItem('textAlignment', textAlignment);
  }, [textAlignment]);

  useEffect(() => {
    localStorage.setItem('dyslexicFontEnabled', dyslexicFontEnabled);
  }, [dyslexicFontEnabled]);
  
  useEffect(() => {
    logger.debug('Setting local storage learningLanguage:', learningLanguage);
    if (learningLanguage && learningLanguage !== nativeLanguage) {
      localStorage.setItem('learningLanguage', learningLanguage);
    } else if (learningLanguage === nativeLanguage) {
      localStorage.removeItem('learningLanguage');
    }
  }, [learningLanguage, nativeLanguage]);
  
  useEffect(() => {
    logger.debug('Setting local storage nativeLanguage:', nativeLanguage);
    localStorage.setItem('nativeLanguage', nativeLanguage);
  }, [nativeLanguage]);

  useEffect(() => {
    localStorage.setItem('nativeLanguageDirection', nativeLanguageDirection);
  }, [nativeLanguageDirection]);

  useEffect(() => {
    localStorage.setItem('learningLanguageDirection', learningLanguageDirection);
  }, [learningLanguageDirection]);
  
  useEffect(() => {
    setPageContent(null);
    setPagePronunciation(null);
    setBookDetails(null);
    setCurrentPageId(null);
    setCurrentPage("");
    setPageAssociations(null);
  }, [learningLanguage, nativeLanguage]);

  useEffect(() => {
    if (pageContent && learningLanguage === 'ja') {
      fetchFurigana();
    }
  }, [pageContent, learningLanguage]);

  const fetchFurigana = async () => {
    try {
      const response = await apiRequest('GET', 'FURIGANA_TRANSCRIPTION', 
        { book_id: bookId, page_id: currentPageId },
        { learning_language: learningLanguage }
      );
      setPageFurigana(response);
    } catch (error) {
      logger.error('Error fetching furigana:', error);
    }
  };

  const toggleFurigana = () => {
    setShowFurigana(prev => !prev);
  };

  const handleThemeSelect = (theme) => {
    logger.debug(`Theme selected: ${theme}`);
    setCurrentTheme(theme);
    document.documentElement.setAttribute('data-theme', theme.toLowerCase());
    localStorage.setItem('selectedTheme', theme);
    setShowThemePicker(false);
  };
  
  const closeThemePicker = () => {
    setShowThemePicker(false);
  };
  
  const loadBook = async () => {
    setIsLoadingBook(true);
    setError('');
    setBookDetails(null);
    setPageContent(null);
    setCurrentPageId(null);
    setPagePronunciation(null);
    
    try {
      const isPremiumUser = userProfile?.subscription_tier === 'premium';
      const languageToUse = isPremiumUser ? learningLanguage : nativeLanguage;
      
      const detailsResponse = await apiRequest('GET', 'BOOK', { book_id: bookId }, { 
        language: languageToUse,
      });
      setBookDetails(detailsResponse);
      
      // Add chapters fetch here
      await fetchChapters();
      
      await new Promise(resolve => setTimeout(resolve, 100));
      
      if (initialPageId) {
        fetchPage(initialPageId);
        setInitialPageId(null);
      } else {
        const firstPageResponse = await apiRequest('GET', 'BOOK_FIRST_PAGE', { book_id: bookId });
        if (firstPageResponse && firstPageResponse.page_id) {
          fetchPage(firstPageResponse.page_id);
        }
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        logger.debug('Request canceled:', error.message);
      } else {
        setError(t('error.loadBook'));
        logger.error('Error loading book:', error);
      }
    } finally {
      setIsLoadingBook(false);
      setIsInitialLoad(false);
    }
  };

  const fetchPronunciation = async (pageId) => {
    try {
      const response = await apiRequest('GET', 'BOOK_PAGE_PRONUNCIATION', 
        { book_id: bookId, page_id: pageId }, 
        { native_language: nativeLanguage, learning_language: learningLanguage }
      );
      setPagePronunciation(response);
    } catch (error) {
      if (axios.isCancel(error)) {
        logger.debug('Pronunciation request canceled:', error.message);
      } else {
        setError(t('error.fetchPronunciation'));
        logger.error('Error fetching pronunciation:', error);
      }
    }
  };

  // Add debounce ref for page navigation
  const pageNavigationDebounceRef = useRef(null);

  const handlePreviousPage = async () => {
    if (isLoadingPage) {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      setIsLoadingPage(false);
    }

    try {
      const response = await apiRequest('GET', 'BOOK_PREVIOUS_PAGE', { book_id: bookId, page_id: currentPageId });
      if (response.previous_page_id) {
        setIsNavigationPending(true);
        setPendingNavigation(response.previous_page_id);
        await fetchPage(response.previous_page_id);
      } else {
        setError(t('error.firstPage'));
        // Use a ref to track the timeout and clear it if needed
        const timeoutId = setTimeout(() => setError(''), 1500);
        return () => clearTimeout(timeoutId);
      }
    } catch (error) {
      setError(t('error.fetchPreviousPage'));
      logger.error('Error fetching previous page:', error);
    }
  };

  const handleNextPage = async () => {
    if (isLoadingPage) {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      setIsLoadingPage(false);
    }

    try {
      const response = await apiRequest('GET', 'BOOK_NEXT_PAGE', { book_id: bookId, page_id: currentPageId });
      if (response.next_page_id) {
        setIsNavigationPending(true);
        setPendingNavigation(response.next_page_id);
        await fetchPage(response.next_page_id);
      } else {
        setError(t('error.lastPage'));
        // Use a ref to track the timeout and clear it if needed
        const timeoutId = setTimeout(() => setError(''), 1500);
        return () => clearTimeout(timeoutId);
      }
    } catch (error) {
      setError(t('error.fetchNextPage'));
      logger.error('Error fetching next page:', error);
    }
  };

  // Add cleanup effect for the navigation debounce timer
  useEffect(() => {
    return () => {
      if (pageNavigationDebounceRef.current) {
        clearTimeout(pageNavigationDebounceRef.current);
      }
    };
  }, []);

  const handleBookSelect = useCallback((selectedBookId, selectedNativeLanguage, selectedLearningLanguage) => {
    // Abort any ongoing fetchPage request
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    
    // Batch all state updates together
    ReactDOM.unstable_batchedUpdates(() => {
      if (selectedNativeLanguage !== nativeLanguage) {
        setNativeLanguage(selectedNativeLanguage);
        onLanguageChange(selectedNativeLanguage);
      }
      if (selectedLearningLanguage !== learningLanguage) {
        setLearningLanguage(selectedLearningLanguage);
      }
      // Set bookId last to trigger the loading effect
      setBookId(selectedBookId);
    });
  }, [nativeLanguage, learningLanguage, onLanguageChange]);

  const handleLanguageSelect = (newNativeLanguage, newLearningLanguage, nativeLanguageDirection, learningLanguageDirection) => {
    if (newNativeLanguage !== nativeLanguage || newLearningLanguage !== learningLanguage || nativeLanguageDirection !== nativeLanguageDirection || learningLanguageDirection !== learningLanguageDirection) {
      // Abort any ongoing fetchPage request
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      
      logger.debug(`Native language: ${newNativeLanguage}, Learning language: ${newLearningLanguage}`);
      setLearningLanguage(newLearningLanguage);
      setLearningLanguageDirection(learningLanguageDirection);
      setNativeLanguage(newNativeLanguage);
      setNativeLanguageDirection(nativeLanguageDirection);
    
      i18n.changeLanguage(newNativeLanguage);
      
      onLanguageChange(newNativeLanguage);

      // Reset all book-related states
      setBookId('');
      setBookDetails(null);
      setCurrentPageId(null);
      setCurrentPage('');
      setNativeContent(null);
      setLearningContent(null);
      setAssociations(null);
      setPagePronunciation(null);
      setPageFurigana(null);
      setPageAssociations(null);
      setPageNumberInput('');
      setIsPageNumberChanged(false);
      setLoadingProgress(0);
      setLoadingLabel(t('welcome.chooseBook'));
      
      // Update URL to remove book and page parameters
      const params = new URLSearchParams();
      params.set('n', newNativeLanguage);
      params.set('l', newLearningLanguage);
      navigate(`?${params.toString()}`, { replace: true });
    }
  };

  // Add new callback for menu state changes
  const handleMenuStateChange = useCallback((isOpen) => {
    setMenuOpen(isOpen);
  }, []);

  // Update ensureMenuOpen to use the new state
  const ensureMenuOpen = useCallback(() => {
    console.log('ensureMenuOpen');
    handleMenuStateChange(true);
    setChangeToBooks(true);
    // Add a timeout to reset changeToBooks after a brief delay
    setTimeout(() => {
      setChangeToBooks(false);
    }, 100);
  }, [handleMenuStateChange]);

  // Update toggleMenu to use the state change handler
  const toggleMenu = useCallback(() => {
    handleMenuStateChange(!menuOpen);
  }, [menuOpen, handleMenuStateChange]);

  const handleTextAlignmentChange = (event) => {
    const newAlignment = event.target.value;
    setTextAlignment(newAlignment);
    localStorage.setItem('textAlignment', newAlignment);
  };
  
  const handleDyslexicFontToggle = () => {
    const newValue = !dyslexicFontEnabled;
    setDyslexicFontEnabled(newValue);
    localStorage.setItem('dyslexicFontEnabled', newValue);
  };

  const toggleNativeLanguageVisibility = () => {
    setShowNativeLanguage(!showNativeLanguage);
  };

  const togglePronunciationVisibility = () => {
    setShowPronunciation(prev => !prev);
  };
  
  // const closeWordDetailsModal = () => {
  //   setIsWordDetailsModalOpen(false);
  //   setSelectedWord(null);
  // };

  useEffect(() => {
    const savedTheme = localStorage.getItem('selectedTheme');
    logger.debug('Loaded saved theme:', savedTheme);
    if (savedTheme) {
      setCurrentTheme(savedTheme);
      setShowThemePicker(false);
      document.documentElement.setAttribute('data-theme', savedTheme.toLowerCase());
    } else {
      setShowThemePicker(true);
    }
    setThemeLoaded(true);
  }, []);

  const handleWordHover = (word) => {
    setHoveredWord(word);
  };

  // const handleWordClick = (word, language) => {
  //   setSelectedWordDetails({
  //     clickedWord: word,
  //     clickedWords: [word],
  //     otherLanguageWords: [],
  //     clickedWordLanguageId: language,
  //     otherLanguageId: language === learningLanguage ? nativeLanguage : learningLanguage,
  //     learningLanguage: learningLanguage,
  //     nativeLanguage: nativeLanguage
  //   });
  //   setIsWordDetailsModalOpen(true);
  // };
  const renderBookTitle = () => {
    if (!bookDetails || !bookDetails.title || !bookDetails.title_words) return null;
  
    const title = bookDetails.title;
    const titleWords = bookDetails.title_words;
    const isSpaceSeparated = title.includes(' ');
  
    // Only attempt to find chapter title if we have both chaptersData and currentChapterId
    let chapterTitle = '';
    if (!isLoadingChapters && chaptersData && currentChapterId) {
      const allChapters = chaptersData.parts?.flatMap(part => part.chapters) || [];
      const chapter = allChapters.find(ch => ch.id === currentChapterId);
      if (chapter) {
        chapterTitle = chapter.title;
      }
    }
  
    let currentIndex = 0;
    return (
      <div className="book-title-container">
        <div 
          className="book-title" 
          dir={learningLanguageDirection}
          title={title} // Show full title on hover
        >
          {title.length > 20 ? (
            `${title.substring(0, 20)}...`
          ) : (
            titleWords.map((word, index) => {
              const startIndex = title.indexOf(word, currentIndex);
              const endIndex = startIndex + word.length;
              const displayText = title.slice(startIndex, endIndex);
    
              currentIndex = endIndex;
    
              return (
                <React.Fragment key={index}>
                  <span className="title-word">
                    {displayText}
                  </span>
                  {isSpaceSeparated && index < titleWords.length - 1 && (
                    <span className="title-space"> </span>
                  )}
                </React.Fragment>
              );
            })
          )}
        </div>
        {!isLoadingChapters && chapterTitle && (
          <div className="chapter-title" dir={learningLanguageDirection}>
            {chapterTitle.length > 30 ? `${chapterTitle.substring(0, 30)}...` : chapterTitle}
          </div>
        )}
      </div>
    );
  };
  
  useEffect(() => {
    if (currentPage) {
      setPageNumberInput(currentPage.toString());
      setIsPageNumberChanged(false);
    }
  }, [currentPage]);

  const handlePageNumberChange = (e) => {
    const value = e.target.value;
    setPageNumberInput(value);
    setIsPageNumberChanged(value !== currentPage.toString());
  };

  const handlePageNumberBlur = () => {
    if (!pageNumberInput) {
      setPageNumberInput(currentPage.toString());
      setIsPageNumberChanged(false);
    }
  };

  const handleJumpToPage = async () => {
    if (!bookId || !pageNumberInput) return;

    try {
      const response = await apiRequest('GET', 'BOOK_PAGE_BY_NUMBER', 
        { book_id: bookId },
        { page_number: pageNumberInput }
      );
      if (response && response.page_id) {
        await fetchPage(response.page_id);
        setIsPageNumberChanged(false);
      } else {
        setError(t('error.invalidPageNumber'));
        setTimeout(() => setError(''), 1500);
      }
    } catch (error) {
      setError(t('error.fetchPageByNumber'));
      logger.error('002 - Error fetching page by number:', error);
    }
  };

  useEffect(() => {
    if (nativeLanguageDirection === 'rtl') {
      document.documentElement.style.setProperty('--menu-toggle-right', 'auto');
      document.documentElement.style.setProperty('--menu-toggle-left', '10px');
    } else {
      document.documentElement.style.setProperty('--menu-toggle-right', '10px');
      document.documentElement.style.setProperty('--menu-toggle-left', 'auto');
    }
  }, [nativeLanguageDirection]);

  // Update the loading progress effect
  useEffect(() => {
    if (!isLoadingPage) {
      setLoadingProgress(100);
      setLoadingLabel('');
      return;
    }

    // Calculate total steps based on enabled features
    const getStepsConfig = () => {
      if (!isPremiumUser) {
        return [{ label: 'loading.page', check: () => !nativeContent }];
      }

      const steps = [
        { label: 'loading.page', check: () => !nativeContent },
        { label: 'loading.learningContent', check: () => !learningContent }
      ];

      if (loadHighlighting) {
        steps.push({ label: 'loading.highlighting', check: () => !associations });
      }

      if (loadPronunciation) {
        steps.push({ label: 'loading.pronunciation', check: () => !pagePronunciation });
        if (learningLanguage === 'ja') {
          steps.push({ label: 'loading.furigana', check: () => !pageFurigana });
        }
      }

      return steps;
    };

    const steps = getStepsConfig();
    const totalSteps = steps.length;

    // Find current step
    const currentStepIndex = steps.findIndex(step => step.check());
    
    if (currentStepIndex === -1) {
      // All steps complete
      setLoadingProgress(100);
      setLoadingLabel('');
    } else {
      // Calculate progress based on current step
      const progress = Math.round((currentStepIndex / totalSteps) * 100);
      setLoadingProgress(progress);
      setLoadingLabel(bookId ? t(steps[currentStepIndex].label) : t('welcome.chooseBook'));
    }

  }, [nativeContent, learningContent, associations, pagePronunciation, pageFurigana, 
    learningLanguage, t, bookId, isPremiumUser, loadHighlighting, loadPronunciation, isLoadingPage]);

  // Fetch bookmarks when user profile changes
  useEffect(() => {
    if (userProfile?.subscription_tier === 'premium') {
      fetchBookmarks();
    }
  }, [userProfile]);

  const fetchBookmarks = async () => {
    try {
      const response = await apiRequest('GET', 'USER_BOOKMARKS');
      setBookmarks(response);
    } catch (error) {
      logger.error('Error fetching bookmarks:', error);
    }
  };

  const handleBookmarkToggle = async (bookId, pageId) => {
    if (!userProfile || userProfile.subscription_tier !== 'premium') {
      setIsProductPromotionModalOpen(true);
      return;
    }

    try {
      // Find any existing bookmark for this book
      const existingBookmarkForBook = bookmarks.find(b => b.book_id === bookId);
      
      // If there's an existing bookmark for this book but different page
      if (existingBookmarkForBook && existingBookmarkForBook.page_id !== pageId) {
        // Delete the old bookmark
        await apiRequest('DELETE', 'USER_BOOKMARK', { 
          book_id: bookId, 
          page_id: existingBookmarkForBook.page_id 
        });
        
        // Create the new bookmark
        await apiRequest('POST', 'USER_BOOKMARK', { 
          book_id: bookId, 
          page_id: pageId 
        }, null, { note: '' });
        
        // Update state
        setBookmarks(prev => [
          ...prev.filter(b => b.book_id !== bookId),
          { book_id: bookId, page_id: pageId }
        ]);
        setCurrentBookmark({ book_id: bookId, page_id: pageId });
        return;
      }
      
      // Handle toggle for exact same page (remove bookmark)
      if (existingBookmarkForBook && existingBookmarkForBook.page_id === pageId) {
        await apiRequest('DELETE', 'USER_BOOKMARK', { 
          book_id: bookId, 
          page_id: pageId 
        });
        setBookmarks(prev => prev.filter(b => b.book_id !== bookId));
        setCurrentBookmark(null);
        return;
      }
      
      // No existing bookmark for this book, create new one
      await apiRequest('POST', 'USER_BOOKMARK', { 
        book_id: bookId, 
        page_id: pageId 
      }, null, { note: '' });
      const newBookmark = { book_id: bookId, page_id: pageId };
      setBookmarks(prev => [...prev, newBookmark]);
      setCurrentBookmark(newBookmark);
      
    } catch (error) {
      logger.error('Error toggling bookmark:', error);
    }
  };

  // Update useEffect for currentPageId to check for bookmark
  useEffect(() => {
    if (bookId && currentPageId) {
      const bookmark = bookmarks.find(b => b.book_id === bookId);
      setCurrentBookmark(bookmark);
    }
  }, [bookId, currentPageId, bookmarks]);

  const handleTextSizeIncrease = () => {
    setTextSize(prevSize => {
      const newSize = Math.min(prevSize + 10, 200); // Max 200%
      localStorage.setItem('textSize', newSize);
      return newSize;
    });
  };

  const handleTextSizeDecrease = () => {
    setTextSize(prevSize => {
      const newSize = Math.max(prevSize - 10, 50); // Min 50%
      localStorage.setItem('textSize', newSize);
      return newSize;
    });
  };

  // Update the admin mode check
  useEffect(() => {
    const checkAdminModeStatus = () => {
      setIsAdminMode(checkAdminMode());
    };

    // Check initially and set up interval
    checkAdminModeStatus();
    const interval = setInterval(checkAdminModeStatus, 1000);
    
    return () => clearInterval(interval);
  }, []);

  const handleContentSave = (newContent) => {
    setNativeContent(newContent.content);
  };
  // Update the effect to load user settings to include feedback state
  useEffect(() => {
    const loadUserSettings = async () => {
      try {
        const response = await apiRequest('GET', 'USER_SETTINGS');
        const settings = response.settings;
        
        // Only update settings if response contains non-empty settings
        if (settings && Object.keys(settings).length > 0) {
          // Update all settings-related state
          setUserSettings(settings);
          setDyslexicFontEnabled(settings.dyslexicFontEnabled);
          setLoadHighlighting(settings.loadHighlighting);
          setLoadPronunciation(settings.loadPronunciation);
          setTextAlignment(settings.textAlignment);
          
          // Update feedback state from backend if premium user
          if (userProfile?.subscription_tier === 'premium') {
            setHasFeedbackBeenGiven(settings.hasFeedbackBeenGiven || false);
            // Also update localStorage to match backend state
            localStorage.setItem('hasFeedbackBeenGiven', settings.hasFeedbackBeenGiven || false);
          }
        }
        
        setSettingsLoaded(true);
      } catch (error) {
        logger.error('Error loading user settings:', error);
        setSettingsLoaded(true);
      }
    };

    loadUserSettings();
  }, [userProfile]);

  // Update effect to show feedback modal
  useEffect(() => {
    if (hasOpenedBook && !showFeedbackModal && !hasFeedbackBeenGiven) {
      const timer = setTimeout(() => {
        // Only show feedback modal if a book is currently selected
        if (bookId) {
          setShowFeedbackModal(true);
        } else {
          // Reset hasOpenedBook if no book is selected when timer expires
          setHasOpenedBook(false);
        }
      }, 5 * 60 * 1000); // 5 minutes in milliseconds

      return () => clearTimeout(timer);
    }
  }, [hasOpenedBook, showFeedbackModal, bookId, hasFeedbackBeenGiven]);

  // Update the close handler to mark feedback as handled regardless of submission
  const handleCloseFeedbackModal = async (wasSubmitted = false) => {
    setShowFeedbackModal(false);
    
    // Mark as given whether submitted or cancelled
    localStorage.setItem('hasFeedbackBeenGiven', 'true');
    setHasFeedbackBeenGiven(true);

    // Update backend if premium user
    if (userProfile?.subscription_tier === 'premium') {
      try {
        await apiRequest('PATCH', 'USER_SETTINGS', {}, {}, {
          ...userSettings,
          hasFeedbackBeenGiven: true
        });
      } catch (error) {
        logger.error('Error updating feedback status:', error);
      }
    }
  };

  // Add function to handle settings changes
  const handleSettingsChange = async (newSettings) => {
    try {
      await apiRequest('PATCH', 'USER_SETTINGS', {}, {}, {
        loadHighlighting: newSettings.loadHighlighting,
        loadPronunciation: newSettings.loadPronunciation,
        textAlignment: newSettings.textAlignment,
        dyslexicFontEnabled: newSettings.dyslexicFontEnabled,
        textSize: newSettings.textSize,
      });
      
      setLoadHighlighting(newSettings.loadHighlighting);
      setLoadPronunciation(newSettings.loadPronunciation);
      setTextAlignment(newSettings.textAlignment);
      setDyslexicFontEnabled(newSettings.dyslexicFontEnabled);
      setTextSize(newSettings.textSize);
    } catch (error) {
      logger.error('Error saving settings:', error);
      setError(t('error.saveSettings'));
      setTimeout(() => setError(''), 1500);
    }
  };

  // Add useEffect to fetch chapters when book changes
  useEffect(() => {
    if (bookId) {
      fetchChapters();
    }
  }, [bookId, fetchChapters]);

  // Update the navigation handlers to include debounce logic and visual feedback
  const handlePreviousPageWithDebounce = () => {
    const now = Date.now();
    if (now - lastNavigationTime < DEBOUNCE_DELAY) {
      return; // Ignore click if too soon after last click
    }
    setLastNavigationTime(now);
    setIsDebounced(true);
    setTimeout(() => setIsDebounced(false), DEBOUNCE_DELAY);
    handlePreviousPage();
  };

  const handleNextPageWithDebounce = () => {
    const now = Date.now();
    if (now - lastNavigationTime < DEBOUNCE_DELAY) {
      return; // Ignore click if too soon after last click
    }
    setLastNavigationTime(now);
    setIsDebounced(true);
    setTimeout(() => setIsDebounced(false), DEBOUNCE_DELAY);
    handleNextPage();
  };

  // Add effect to track when a book is opened
  useEffect(() => {
    if (bookId && !hasOpenedBook) {
      setHasOpenedBook(true);
    }
  }, [bookId]);

  return (
    <div className={`parallel-text-container ${dyslexicFontEnabled ? 'dyslexic-font' : ''}`} dir={nativeLanguageDirection}>
        {showThemePicker && (
          <ThemePickerWelcomeScreen
          onThemeSelect={handleThemeSelect}
          onClose={closeThemePicker}
          currentTheme={currentTheme}
        />
        )}
        <button className="menu-toggle" onClick={toggleMenu}>☰</button>
        <Menu 
          onBookSelect={handleBookSelect} 
          onLanguageSelect={handleLanguageSelect} 
          open={menuOpen ? 'open' : ''} 
          currentTheme={currentTheme} 
          onThemeChange={handleThemeSelect}
          onLanguageOptionsChange={handleLanguageOptionsChange}
          textAlignment={textAlignment}
          onTextAlignmentChange={handleTextAlignmentChange}
          dyslexicFontEnabled={dyslexicFontEnabled}
          onDyslexicFontToggle={handleDyslexicFontToggle}
          onOpenCookiePreferences={openCookiePreferences}
          initialNativeLanguage={nativeLanguage}
          initialLearningLanguage={learningLanguage}
          userProfile={userProfile}
          isLoadingProfile={isLoadingProfile}
          onLogin={handleLogin}
          onSignup={handleSignup}
          onLogout={handleLogout}
          onPasswordReset={handlePasswordReset}
          nativeLanguageDirection={nativeLanguageDirection}
          shouldTriggerSubscribe={shouldTriggerSubscribe}
          onSubscribeTriggered={onSubscribeTriggered}
          getURLWithParams={getURLWithParams}
          onRefetchProfile={onRefetchProfile}
          bookmarks={bookmarks}
          onBookmarkClick={loadBookPage}
          selectedBookId={bookId}
          loadHighlighting={loadHighlighting}
          loadPronunciation={loadPronunciation}
          onSettingsChange={handleSettingsChange}
          userSettings={userSettings}
          changeToBooks={changeToBooks}
        />
        <div className="toolbar-and-content" dir={nativeLanguageDirection}>
        <Toolbar
          onToggleNativeLanguage={toggleNativeLanguageVisibility}
          onTogglePronunciation={togglePronunciationVisibility}
          onToggleFurigana={toggleFurigana}
          bookDetails={bookDetails}
          currentPageId={currentPageId}
          nativeLanguage={nativeLanguage}
          currentPageNumber={currentPage}
          learningLanguage={learningLanguage}
          isContentLoaded={!!(nativeContent && learningContent)}
          isPronunciationLoaded={!!pagePronunciation}
          isFuriganaLoaded={!!pageFurigana}
          showFurigana={showFurigana}
          nativeLanguageDirection={nativeLanguageDirection}
          userProfile={userProfile}
          bookId={bookId}
          onBookmarkToggle={handleBookmarkToggle}
          isBookmarked={!!currentBookmark}
          onTextSizeIncrease={handleTextSizeIncrease}
          onTextSizeDecrease={handleTextSizeDecrease}
        />
          <div className={`book-content ${!bookId ? 'welcome-state' : ''} ${dyslexicFontEnabled ? 'dyslexic-font' : ''}`} dir={nativeLanguageDirection}>
            {!bookId ? (
              <InteractiveWelcomePrompt 
                onMenuOpen={ensureMenuOpen}
              />
            ) : (
              <>
                <div className="navigation-buttons">
                  <button 
                    onClick={handlePreviousPageWithDebounce}
                    disabled={!bookDetails || (currentPage === 1 && !isNavigationPending)}
                    className={`nav-button-left ${isDebounced ? 'debounced' : ''}`}
                  >
                    {t('navigation.previousPage')}
                  </button>
                  {renderBookTitle()}
                  <button 
                    onClick={handleNextPageWithDebounce}
                    disabled={!bookDetails || (currentPage === bookDetails.page_count && !isNavigationPending)}
                    className={`nav-button-left ${isDebounced ? 'debounced' : ''}`}
                  >
                    {t('navigation.nextPage')}
                  </button>
                </div>
                {loadingProgress < 100 && loadingLabel && (
                  <LoadingProgress 
                    progress={loadingProgress} 
                    label={
                      <div className="loading-label">
                        <span className="loading-text">{loadingLabel}</span>
                        <LoadingDots direction={nativeLanguageDirection} />
                      </div>
                    } 
                  />
                )}

                <ParallelTextHistory 
                  dyslexicFontEnabled={dyslexicFontEnabled}
                  textAlignment={textAlignment}
                  learningContent={learningContent}
                  nativeContent={nativeContent}
                  associations={associations}
                  pagePronunciation={pagePronunciation}
                  pageFurigana={pageFurigana}
                  learningLanguage={learningLanguage}
                  nativeLanguage={nativeLanguage}
                  showNativeLanguage={showNativeLanguage}
                  showPhonetics={showPhonetics}
                  showFurigana={showFurigana}
                  nativeLanguageDirection={nativeLanguageDirection}
                  learningLanguageDirection={learningLanguageDirection}
                  userProfile={userProfile}
                  textSize={textSize}
                  menuOpen={menuOpen}
                  isLastPage={bookDetails && currentPage === bookDetails.page_count}
                  userSettings={userSettings}
                />
                
                {bookDetails && (
                  <div className="page-navigation" dir={nativeLanguageDirection}>
                    <div className="book-page-of-total">
                      <input
                        type="text"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        value={pageNumberInput}
                        onChange={handlePageNumberChange}
                        onBlur={handlePageNumberBlur}
                        className="current-page-input"
                      />
                      {t('page.ofTotal')} {bookDetails.page_count}
                      <button 
                        className="skip-to-page-button"
                        onClick={handleJumpToPage} 
                        disabled={!isPageNumberChanged}
                      >
                        {t('page.skipTo')}
                      </button>
                    </div>
                  </div>
                )}
                
                {error && <p className="error-loading-page" style={{color: 'red'}}>{error}</p>}
              </>
            )}
          </div>
        </div>
        <AdminToolbar
          nativeContent={nativeContent}
          nativeContentPath={nativeContentPath}
          onContentSave={handleContentSave}
          isVisible={isAdminMode}
        />
        <FeedbackModal
          isOpen={showFeedbackModal}
          onClose={(wasSubmitted) => handleCloseFeedbackModal(wasSubmitted)}
          bookDetails={bookDetails}
          currentPageId={currentPageId}
          nativeLanguage={nativeLanguage}
          learningLanguage={learningLanguage}
        />
      </div>
  );
};

export default ParallelTextViewer;
