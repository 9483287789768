import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const TermsOfService = () => {
  const { t, i18n } = useTranslation();

  const userLocale = localStorage.getItem('nativeLanguage');

  // Add check for dyslexic font setting
  const isDyslexicFont = localStorage.getItem('dyslexicFontEnabled') === 'true';

  useEffect(() => {
    if (userLocale) {
      i18n.changeLanguage(userLocale);
    }
    document.title = "ABAL | " + t('termsOfService.title');
  }, [userLocale, i18n, t]);

  return (
    <div className={`policy-container ${isDyslexicFont ? 'dyslexic-font' : ''}`}>
      <h1>{t('termsOfService.title')}</h1>
      <p>{t('termsOfService.introduction')}</p>
      <h2>{t('termsOfService.serviceDefinition')}</h2>
      <p>{t('termsOfService.serviceDescription')}</p>
      <h2>{t('termsOfService.userObligations')}</h2>

      <p>{t('termsOfService.obligations')}</p>
      <h2>{t('termsOfService.intellectualProperty')}</h2>
      <p>{t('termsOfService.ipRights')}</p>
      <h2>{t('termsOfService.limitations')}</h2>
      <p>{t('termsOfService.liabilityTerms')}</p>
      <h2>{t('termsOfService.termination')}</h2>
      <p>{t('termsOfService.terminationTerms')}</p>
      <h2>{t('termsOfService.changes')}</h2>
      <p>{t('termsOfService.changesPolicy')}</p>
    </div>
  );
};

export default TermsOfService;