import React from 'react';
import './css/Modal.css';

const Modal = ({ children, onClose, showCloseButton = true }) => {
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-body">
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;