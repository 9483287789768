import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const PrivacyPolicy = () => {
  const { t, i18n } = useTranslation();

  const userLocale = localStorage.getItem('nativeLanguage');

  // Add check for dyslexic font setting
  const isDyslexicFont = localStorage.getItem('dyslexicFontEnabled') === 'true';

  useEffect(() => {
    if (userLocale) {
      i18n.changeLanguage(userLocale);
    }
    document.title = "ABAL | " + t('privacyPolicy.title');
  }, [userLocale, i18n, t]);

  return (
    <div className={`policy-container ${isDyslexicFont ? 'dyslexic-font' : ''}`}>
      <h1>{t('privacyPolicy.title')}</h1>
      <p>{t('privacyPolicy.introduction')}</p>
      <h2>{t('privacyPolicy.informationWeCollect')}</h2>
      <p>{t('privacyPolicy.collectedInformation')}</p>
      <h2>{t('privacyPolicy.howWeUseInformation')}</h2>
      <p>{t('privacyPolicy.informationUsage')}</p>
      <h2>{t('privacyPolicy.dataProtection')}</h2>
      <p>{t('privacyPolicy.protectionMeasures')}</p>
      <h2>{t('privacyPolicy.yourRights')}</h2>
      <p>{t('privacyPolicy.userRights')}</p>
    </div>
  );
};

export default PrivacyPolicy;