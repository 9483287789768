import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { apiRequest } from '../config/api.js';
import { useLogger } from '../hooks/useLogger';
import { Eye, EyeOff } from 'lucide-react';
import './css/Profile.css';
import { PRICING_URL } from '../config/app';
import Modal from './Modal';

const Profile = ({ handleLogin, handleLogout, userProfile, onRefetchProfile }) => {
  const { t, i18n } = useTranslation();
  const [email, setEmail] = useState('');
  const [oneTimeCode, setOneTimeCode] = useState('');
  const [showOtcForm, setShowOtcForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');
  const logger = useLogger();
  const [isCheckoutModalOpen, setIsCheckoutModalOpen] = useState(false);
  const [isLoadingProfile, setIsLoadingProfile] = useState(false);
  const [hasHandledSubscribe, setHasHandledSubscribe] = useState(false);
  const [isSubscribeFlow, setIsSubscribeFlow] = useState(false);
  const [isGoogleScriptLoaded, setIsGoogleScriptLoaded] = useState(false);

  useEffect(() => {
    if (userProfile) {
      logger.info('Profile > User profile:', userProfile);
      setStatusMessage('');
    }
  }, [userProfile]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.get('action') === 'subscribe' && !hasHandledSubscribe) {
      logger.info('Profile > Subscribing user');
      if (userProfile) {
        logger.info('Profile > Subscribing user with profile');
        setHasHandledSubscribe(true);
        handleSubscriptionClick();
        setIsSubscribeFlow(false);
      } else {
        logger.info('Profile > Subscribing user without profile');
        setIsSubscribeFlow(true);
      }
    }
    if (hasHandledSubscribe) {
      params.delete('action');
      const newUrl = params.toString() 
        ? `${window.location.pathname}?${params.toString()}`
        : window.location.pathname;
      window.history.replaceState({}, '', newUrl);
    }
  }, [userProfile, hasHandledSubscribe]);

  useEffect(() => {
    const loadGoogleScript = () => {
      const script = document.createElement('script');
      script.src = 'https://accounts.google.com/gsi/client';
      script.async = true;
      script.defer = true;
      script.onload = () => {
        setIsGoogleScriptLoaded(true);
      };
      document.body.appendChild(script);
    };

    loadGoogleScript();
    
    return () => {
      const script = document.querySelector('script[src="https://accounts.google.com/gsi/client"]');
      if (script) {
        document.body.removeChild(script);
      }
    };
  }, []);

  const onLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const result = await handleLogin(email, isSubscribeFlow);
      setStatusMessage(result.message || t('profile.magicLinkSent'));
      setShowOtcForm(true);
    } catch (error) {
      setStatusMessage(error.response?.data?.message || t('profile.loginError'));
    } finally {
      setIsLoading(false);
    }
  };

  const handleOtcSubmit = async (e) => {
    console.log('Profile > Handling OTP submit');
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await apiRequest('POST', 'MAGIC_LINK_CONFIRM', {}, {}, { otc: oneTimeCode });
      onRefetchProfile();
      setShowOtcForm(false);
      setOneTimeCode('');
    } catch (error) {
      console.log('Profile > Error confirming magic link:', error);
      setStatusMessage(error.response?.data?.message || t('profile.invalidCode'));
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubscriptionClick = async () => {
    try {
      const endpoint = userProfile.subscription_tier === 'none' ? 'CREATE_CHECKOUT_SESSION' : 'CREATE_PORTAL_SESSION';
      const response = await apiRequest('POST', endpoint);
      
      if (!response || !response.url) {
        throw new Error('Invalid session response');
      }

      // Check if we're on a mobile device
      const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

      if (isMobile) {
        // On mobile, do a direct redirect
        window.location.href = response.url;
      } else {
        // On desktop, continue using the popup approach
      const width = 500;
      const height = 700;
      const left = (window.screen.width - width) / 2;
      const top = (window.screen.height - height) / 2;
      window.open(
        response.url,
        'Stripe Session',
        `width=${width},height=${height},left=${left},top=${top},location=yes,menubar=no,toolbar=no,status=no`
      );
      setIsCheckoutModalOpen(true);
      }
    } catch (error) {
      logger.error('Error creating session:', error);
      setStatusMessage(t('profile.subscriptionError'));
    }
  };

  const handleContinueAfterCheckout = async () => { 
    onRefetchProfile();
    setIsCheckoutModalOpen(false);
  };

  const handleGoogleCredentialResponse = async (response) => {
    setIsLoading(true);
    try {
      const result = await apiRequest('POST', 'GOOGLE_AUTH', {}, {}, {
        credential: response.credential
      });
      onRefetchProfile();
      setStatusMessage(t('profile.loginSuccess'));
    } catch (error) {
      setStatusMessage(error.response?.data?.message || t('profile.loginError'));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isGoogleScriptLoaded && !userProfile) {
      window.google?.accounts.id.initialize({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        callback: handleGoogleCredentialResponse
      });
      
      window.google?.accounts.id.renderButton(
        document.getElementById('googleSignInDiv'),
        {}
      );
    }
  }, [isGoogleScriptLoaded, userProfile]);

  return (
    <div className="profile-panel">
      {isLoading ? (
        <div className="spinner"></div>
      ) : userProfile ? (
        <>
          <div className="profile-section">
            <div className="profile-info-section">
              <div className="email-section">
                <label className="email-label">{t('profile.email')}:</label>
                <span className="email-address">{userProfile.email}</span>
              </div>
              <div className="logout-section">
                <button onClick={handleLogout} className="logout-button">
                  {t('profile.logout')}
                </button>
              </div>
            </div>
            <div className="subscription-section">
              <h3>{t('profile.subscription')}</h3>
              {userProfile.subscription_tier === 'none' && userProfile.has_trialed && (
                <p className="trial-ended-message">
                  {t('profile.trialEnded')}
                </p>
              )}
              {userProfile.subscription_tier && (
                <div className="current-subscription">
                  <p>{t(`subscription.tier.${userProfile.subscription_tier.toLowerCase()}`)}</p>
                  {userProfile.subscription_status && <p>{t(`subscription.status.${userProfile.subscription_status}`)}</p>}
                  {userProfile.subscription_end_date && (
                    <p>{t('profile.subscriptionEndDate')}: {new Date(userProfile.subscription_end_date).toLocaleDateString(i18n.language, {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                      hour: 'numeric',
                    })}</p>
                  )}
                </div>
              )}
              <button 
                className="subscription-button" 
                onClick={handleSubscriptionClick}
              >
                {t('profile.' + (userProfile.subscription_tier === 'none' 
                  ? (userProfile.has_trialed ? 'subscribe' : 'trialSubscription') 
                  : 'manageSubscription'))}
              </button>
              {userProfile.subscription_tier === 'none' && (
                <div className="subscription-info">
                  <button onClick={() => window.open(`${PRICING_URL}`, '_blank')} className="subscription-learn-more">
                    {t('profile.learnMore')}
                  </button>
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        <>
          <h3>{t('profile.magicEntry')}</h3>
          {!showOtcForm && (
            <>
              <div id="googleSignInDiv" className="google-sign-in"></div>
              <div className="separator">
                <span>{t('common.or')}</span>
              </div>
              <form onSubmit={onLogin} className="login-form">
                <input
                  type="email"
                  placeholder={t('profile.email')}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <button className="login-button" type="submit">{t('profile.requestMagicLink')}</button>
              </form>
            </>
          )}
          {statusMessage && (
            <p className={`status-message ${statusMessage.toLowerCase().includes('error') || statusMessage.toLowerCase().includes('invalid') ? 'error' : 'success'}`}>
              {statusMessage.toLowerCase().includes('error') || statusMessage.toLowerCase().includes('invalid') ? `❌ ${statusMessage}` : `✅ ${statusMessage}`}
            </p>
          )}
          {showOtcForm && (
            <form onSubmit={handleOtcSubmit} className="login-form">
              <input
                type="text"
                placeholder={t('profile.enterCode')}
                value={oneTimeCode}
                onChange={(e) => setOneTimeCode(e.target.value)}
                pattern="[0-9]*"
                inputMode="numeric"
                required
              />
              <button className="login-button" type="submit">{t('profile.enter')}</button>
            </form>
          )}
          {isSubscribeFlow && (
            <p className="status-message">💎 {t('profile.promptSubscription')}</p>
          )}
        </>
      )}
      
      {isCheckoutModalOpen && (
        <Modal onClose={() => setIsCheckoutModalOpen(false)} showCloseButton={true}>
          <div className="checkout-modal-content">
            <h2>{t(userProfile.subscription_tier === 'none' ? 'checkout.completeProcess' : 'manageSubscription.title')}</h2>
            <p>{t(userProfile.subscription_tier === 'none' ? 'checkout.clickContinue' : 'manageSubscription.message')}</p>
            <button 
              onClick={handleContinueAfterCheckout}
              disabled={isLoadingProfile}
              className="continue-button"
            >
              {isLoadingProfile ? (
                <div className="spinner"></div>
              ) : (
                t('common.continue')
              )}
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Profile;


