import React, { useState, useEffect } from 'react';
import { apiRequest } from '../config/api';
import { useTranslation } from 'react-i18next';
import './css/Toolbar.css';
import './css/common.css';
import ProductPromotionModal from './ProductPromotionModal';
import FeedbackModal from './FeedbackModal';
import { useLogger } from '../hooks/useLogger';
import { Languages, Speech, MessageSquare, Share2, Bookmark, Plus, Minus } from 'lucide-react';
import ShareModal from './ShareModal';

const Toolbar = ({ 
  onToggleNativeLanguage, 
  onTogglePronunciation, 
  onToggleFurigana,
  bookDetails, 
  currentPageId, 
  currentPageNumber,
  nativeLanguage, 
  learningLanguage, 
  isContentLoaded,
  isPronunciationLoaded,
  isFuriganaLoaded,
  showFurigana,
  nativeLanguageDirection,
  userProfile,
  bookId,
  onBookmarkToggle,
  isBookmarked,
  onTextSizeIncrease,
  onTextSizeDecrease,
}) => {
  const { t } = useTranslation();
  const logger = useLogger();
  const [nativeLanguageEnabled, setNativeLanguageEnabled] = useState(false);
  const [pronunciationEnabled, setPronunciationEnabled] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isProductPromotionModalOpen, setIsProductPromotionModalOpen] = useState(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [showBookmarkToast, setShowBookmarkToast] = useState(false);
  const [bookmarkToastMessage, setBookmarkToastMessage] = useState('');

  const isPageLoaded = !!currentPageId;
  const isPremiumUser = userProfile?.subscription_tier === 'premium';

  const handleToggleNativeLanguage = () => {
    setNativeLanguageEnabled(prev => !prev);
    onToggleNativeLanguage(); 
  };

  const handleTogglePronunciation = () => {
    setPronunciationEnabled(prev => !prev);
    onTogglePronunciation();
  };

  const handleToggleFurigana = () => {
    onToggleFurigana();
  };

  const handleReportIssue = () => {
    setIsModalOpen(true);
  };

  const handlePremiumFeatureClick = (callback) => {
    return () => isPremiumUser ? callback() : setIsProductPromotionModalOpen(true);
  };

  const handleBookmarkClick = async () => {
    await onBookmarkToggle(bookId, currentPageId);
    setBookmarkToastMessage(isBookmarked ? t('toolbar.bookmarkRemoved') : t('toolbar.bookmarkAdded'));
    setShowBookmarkToast(true);
    setTimeout(() => {
      setShowBookmarkToast(false);
    }, 2000);
  };

  useEffect(() => {
    logger.debug(`Native language direction: ${nativeLanguageDirection}`);
  }, [nativeLanguageDirection]);

  return (
    <div className="toolbar" dir={nativeLanguageDirection}>
      {isPremiumUser && (
        <button 
          className={`native-language-toggle ${nativeLanguageEnabled ? 'enabled' : ''}`}
          onClick={handleToggleNativeLanguage}
          disabled={!isContentLoaded}
        >
          <Languages size={20} />
        </button>
      )}
      {!isPremiumUser && (
        <button 
          className="native-language-toggle premium-feature"
          onClick={() => setIsProductPromotionModalOpen(true)}
          title={t('toolbar.showNativeLanguage')}
        >
          <Languages size={20} />
        </button>
      )}
      <button 
        className={`pronunciation-toggle ${!isPremiumUser ? 'premium-feature' : ''}`}
        onClick={handlePremiumFeatureClick(onTogglePronunciation)}
        disabled={isPremiumUser && (!isContentLoaded || !isPronunciationLoaded)}
        title={pronunciationEnabled ? t('toolbar.hidePronunciations') : t('toolbar.showPronunciations')}
      >
        <Speech size={20} />
      </button>
      {learningLanguage === 'ja' && (
        <button 
          className={`furigana-toggle ${!isPremiumUser ? 'premium-feature' : ''}`}
          onClick={handlePremiumFeatureClick(onToggleFurigana)}
          disabled={isPremiumUser && (!isContentLoaded || !isFuriganaLoaded)}
          title={showFurigana ? t('toolbar.hideFurigana') : t('toolbar.showFurigana')}
        >
          <strong style={{fontSize: '1.3em'}}>か</strong>
        </button>
      )}
      {isPremiumUser ? (
        <button 
          className={`bookmark-toggle ${isBookmarked ? 'active' : ''}`}
          onClick={handleBookmarkClick}
          disabled={!isContentLoaded}
          title={!isContentLoaded ? t('toolbar.setBookmarkDisabled') : 
                 isBookmarked ? t('toolbar.removeBookmark') :
                 t('toolbar.setBookmark', { page: currentPageNumber })}
        >
          <Bookmark size={20} />
        </button>
      ) : (
        <button 
          className="bookmark-toggle premium-feature"
          onClick={() => setIsProductPromotionModalOpen(true)}
          title={t('toolbar.setBookmark', { page: currentPageNumber })}
        >
          <Bookmark size={20} />
        </button>
      )}
      <button 
        className="report-issue"
        onClick={handleReportIssue}
        title={t('toolbar.submitFeedback')}
      >
        <MessageSquare size={20} />
      </button>
      <button 
        className="share-button"
        onClick={() => setIsShareModalOpen(true)}
        title={t('toolbar.share')}
      >
        <Share2 size={20} />
      </button>
      <button 
        className="text-size-button"
        onClick={onTextSizeDecrease}
        disabled={!isContentLoaded}
        title={t('toolbar.decreaseTextSize')}
      >
        <Minus size={20} />
      </button>
      <button 
        className="text-size-button"
        onClick={onTextSizeIncrease}
        disabled={!isContentLoaded}
        title={t('toolbar.increaseTextSize')}
      >
        <Plus size={20} />
      </button>
      <FeedbackModal 
        isOpen={isModalOpen} 
        onClose={() => setIsModalOpen(false)} 
        bookDetails={bookDetails}
        currentPageId={currentPageId}
        nativeLanguage={nativeLanguage}
        learningLanguage={learningLanguage}
      />
      {/* Add ProductPromotionModal */}
      <ProductPromotionModal
        isOpen={isProductPromotionModalOpen}
        onClose={() => setIsProductPromotionModalOpen(false)}
      />
      <ShareModal
        isOpen={isShareModalOpen}
        onClose={() => setIsShareModalOpen(false)}
        url={window.location.href}
        title={bookDetails?.title}
      />
      {showBookmarkToast && (
        <div className="bookmark-toast">
          {bookmarkToastMessage}
        </div>
      )}
    </div>
  );
};

export default Toolbar;