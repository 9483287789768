import React, { useState, useEffect } from 'react';
import { Check } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useLogger } from '../hooks/useLogger';

const Settings = ({ 
  currentTheme, 
  onThemeChange, 
  textAlignment, 
  onTextAlignmentChange, 
  dyslexicFontEnabled, 
  onDyslexicFontToggle, 
  onOpenCookiePreferences,
  userProfile,
  loadHighlighting,
  loadPronunciation,
  onSettingsChange,
  textSize,
  userSettings,
}) => {
  const { t } = useTranslation();
  const logger = useLogger('Settings');
  const [selectedFont, setSelectedFont] = useState(
    localStorage.getItem('selectedFont') || 'georgia'
  );

  const handleSettingChange = (setting, value) => {
    const newSettings = {
      loadHighlighting,
      loadPronunciation,
      textAlignment,
      dyslexicFontEnabled,
      textSize,
      [setting]: value
    };
    
    onSettingsChange(newSettings);
  };

  const isPremiumUser = userProfile?.subscription_tier === 'premium';

  const themeOptions = [
    {
      name: 'classic',
      colors: {
        primary: '#f4efe1',
        secondary: '#8b7d6b',
        accent: '#b8a88a',
      },
    },
    {
      name: 'dark',
      colors: {
        primary: '#1a1a2e',
        secondary: '#e94560',
        accent: '#0f3460',
      },
    },
    {
      name: 'vibrant',
      colors: {
        primary: '#ffffff',
        secondary: '#ff6b6b',
        accent: '#4ecdc4',
      },
    },
  ];

  const fontOptions = [
    { value: 'arial', label: 'Arial' },
    { value: 'georgia', label: 'Georgia' },
    { value: 'helvetica', label: 'Helvetica' },
    { value: 'merriweather', label: 'Merriweather' },
    { value: 'times', label: 'Times New Roman' },
    { value: 'verdana', label: 'Verdana' }
  ];

  // Add useEffect to handle initial font load
  useEffect(() => {
    const font = selectedFont;
    const fontFamily = `${font}, sans-serif`;
    document.documentElement.style.setProperty('--content-font', fontFamily);
    document.body.style.fontFamily = fontFamily;
  }, []); // Run once on mount

  // Use useEffect to sync with userSettings when they load
  useEffect(() => {
    if (userSettings) {
      // The parent components will handle setting these values
      // through their respective state management
      logger.debug('User settings loaded:', userSettings);
    }
  }, [userSettings, logger]);

  const handleFontChange = (event) => {
    const font = event.target.value;
    setSelectedFont(font);
    localStorage.setItem('selectedFont', font);
    
    // Simplified font-family logic since we removed 'system' option
    const fontFamily = `${font}, sans-serif`;
    
    document.documentElement.style.setProperty('--content-font', fontFamily);
    document.body.style.fontFamily = fontFamily;
  };

  return (
    <div className="settings-panel">
      <div className="menu-section">
        <h3>{t('menu.theme')}</h3>
        <div className="theme-options">
          {themeOptions.map((theme) => (
            <div
              key={theme.name}
              className={`theme-option ${currentTheme === theme.name ? 'active' : ''}`}
              onClick={() => onThemeChange(theme.name)}
            >
              <div className="theme-colors">
                <div style={{ backgroundColor: theme.colors.primary }} />
                <div style={{ backgroundColor: theme.colors.secondary }} />
                <div style={{ backgroundColor: theme.colors.accent }} />
              </div>
              {currentTheme.toLowerCase() === theme.name.toLowerCase() && (
                <Check size={16} className="theme-check" />
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="menu-section">
        <h3>{t('settings.textAlignment')}</h3>
        <select 
          onChange={onTextAlignmentChange}
          value={textAlignment} 
          className="filter-select"
        >
          <option value="justify-center">{t('settings.center')}</option>
          <option value="justify-left">{t('settings.leftJustify')}</option>
          <option value="justify-right">{t('settings.rightJustify')}</option>
          <option 
            value="interlace" 
            disabled={!isPremiumUser}
          >
            {t('settings.interlace')}
          </option>
        </select>
      </div>
      <div className="menu-section">
        <h3>{t('settings.font')}</h3>
        <select 
          onChange={handleFontChange}
          value={selectedFont}
          className="filter-select"
          disabled={dyslexicFontEnabled}
          title={dyslexicFontEnabled ? t('settings.fontDisabledDueToDyslexic') : ''}
        >
          {fontOptions.map(font => (
            <option key={font.value} value={font.value}>
              {font.label}
            </option>
          ))}
        </select>
      </div>
      <div className="menu-section">
        <h3>{t('settings.dyslexicFont')}</h3>
        <button 
          className={`dyslexic-font-toggle ${dyslexicFontEnabled ? 'enabled' : ''}`} 
          onClick={onDyslexicFontToggle} 
          style={{fontFamily: 'OpenDyslexic', fontSize: '0.8rem'}}
        >
          {dyslexicFontEnabled ? t('settings.disableDyslexicFont') : t('settings.enableDyslexicFont')}
        </button>
      </div>
      <div className="menu-section">
        <h3>{t('settings.cookiePreferences')}</h3>
        <button 
          className="cookie-preferences-button" 
          onClick={onOpenCookiePreferences}
        >
          {t('settings.openCookiePreferences')}
        </button>
      </div>
      {isPremiumUser && (
        <div className="menu-section">
          <h3>{t('settings.contentLoading')}</h3>
          <div className="setting-item">
            <label className="toggle-switch">
              <input
                type="checkbox"
                checked={loadHighlighting}
                onChange={(e) => handleSettingChange('loadHighlighting', e.target.checked)}
              />
              <span className="slider"></span>
              <span className="toggle-label">{t('settings.loadHighlighting')}</span>
            </label>
          </div>
          <div className="setting-item">
            <label className="toggle-switch">
              <input
                type="checkbox"
                checked={loadPronunciation}
                onChange={(e) => handleSettingChange('loadPronunciation', e.target.checked)}
              />
              <span className="slider"></span>
              <span className="toggle-label">{t('settings.loadPronunciation')}</span>
            </label>
          </div>
        </div>
      )}
    </div>
  );
};

export default Settings;