import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams, useNavigate } from 'react-router-dom';

const Billing = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const status = searchParams.get('status');

  const handleClose = () => {
    if (window.opener) {
      // This is a popup window, we can safely close it
      window.close();
    } else {
      // This is the main window, redirect back to profile
      navigate('/profile');
    }
  };

  return (
    <div className="policy-container">
      <h1>{t(`stripe.payment${status === 'failed' ? 'Failure' : 'Success'}.title`)}</h1>
      <p>{t(`stripe.payment${status === 'failed' ? 'Failure' : 'Success'}.message`)}</p>
      <button 
        className="btn btn-primary" 
        onClick={handleClose}
      >
        {t('common.close')}
      </button>
    </div>
  );
};

export default Billing;