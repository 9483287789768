import React, { useState } from 'react';
import { S3Client, PutObjectCommand, GetObjectCommand } from '@aws-sdk/client-s3';
import { CloudFrontClient, CreateInvalidationCommand } from '@aws-sdk/client-cloudfront';
import './css/AdminToolbar.css';

const AdminToolbar = ({ 
  nativeContent, 
  nativeContentPath,
  onContentSave,
  isVisible 
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedContent, setEditedContent] = useState('');
  const [bucketName, setBucketName] = useState('');
  const [distributionId, setDistributionId] = useState('');
  const [awsCredentials, setAwsCredentials] = useState({
    accessKeyId: '',
    secretAccessKey: '',
    region: ''
  });
  const [showCredentialsModal, setShowCredentialsModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleInspect = async () => {
    try {
      setIsLoading(true);

      // Create S3 client with credentials
      const s3Client = new S3Client({
        region: awsCredentials.region,
        credentials: {
          accessKeyId: awsCredentials.accessKeyId,
          secretAccessKey: awsCredentials.secretAccessKey
        }
      });

      // Get object from S3
      const getParams = {
        Bucket: bucketName,
        Key: nativeContentPath
      };

      const response = await s3Client.send(new GetObjectCommand(getParams));
      const contentString = await response.Body.transformToString();
      
      // Format the JSON for display
      const formattedContent = JSON.stringify(JSON.parse(contentString), null, 2);
      setEditedContent(formattedContent);
      setIsEditing(true);
    } catch (error) {
      console.error('Error fetching content from S3:', error);
      alert('Failed to fetch content from S3. Check console for details.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSave = async () => {
    try {
      setIsLoading(true);

      // Create S3 client with credentials
      const s3Client = new S3Client({
        region: awsCredentials.region,
        credentials: {
          accessKeyId: awsCredentials.accessKeyId,
          secretAccessKey: awsCredentials.secretAccessKey
        }
      });

      // Create CloudFront client
      const cloudFrontClient = new CloudFrontClient({
        region: awsCredentials.region,
        credentials: {
          accessKeyId: awsCredentials.accessKeyId,
          secretAccessKey: awsCredentials.secretAccessKey
        }
      });

      // Prepare the file for upload
      const params = {
        Bucket: bucketName,
        Key: nativeContentPath,
        Body: editedContent,
        ContentType: 'application/json'
      };

      // Upload to S3
      await s3Client.send(new PutObjectCommand(params));
      
      // Create CloudFront invalidation
      if (distributionId) {
        const invalidationParams = {
          DistributionId: distributionId,
          InvalidationBatch: {
            CallerReference: `${Date.now()}`,
            Paths: {
              Quantity: 1,
              Items: [`/${nativeContentPath}`]
            }
          }
        };

        await cloudFrontClient.send(new CreateInvalidationCommand(invalidationParams));
      }
      
      // Update local content
      onContentSave(JSON.parse(editedContent));
      setIsEditing(false);
    } catch (error) {
      console.error('Error saving content:', error);
      alert('Failed to save content. Check console for details.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    setIsEditing(false);
    setEditedContent('');
  };

  if (!isVisible) return null;

  return (
    <div className="admin-toolbar">
      <div className="admin-controls">
        <input
          type="text"
          placeholder="S3 Bucket Name"
          value={bucketName}
          onChange={(e) => setBucketName(e.target.value.trim())}
        />
        <input
          type="text"
          placeholder="CloudFront Distribution ID"
          value={distributionId}
          onChange={(e) => setDistributionId(e.target.value.trim())}
        />
        <button onClick={() => setShowCredentialsModal(true)}>
          Setup Credentials
        </button>
        <button 
          onClick={handleInspect}
          disabled={isLoading || !bucketName || !awsCredentials.accessKeyId}
        >
          {isLoading ? 'Loading...' : 'Inspect Content'}
        </button>
      </div>

      {showCredentialsModal && (
        <div className="credentials-modal">
          <h3>AWS Credentials</h3>
          <input
            type="text"
            placeholder="Access Key ID"
            value={awsCredentials.accessKeyId}
            onChange={(e) => setAwsCredentials(prev => ({
              ...prev,
              accessKeyId: e.target.value
            }))}
          />
          <input
            type="password"
            placeholder="Secret Access Key"
            value={awsCredentials.secretAccessKey}
            onChange={(e) => setAwsCredentials(prev => ({
              ...prev,
              secretAccessKey: e.target.value
            }))}
          />
          <input
            type="text"
            placeholder="Region"
            value={awsCredentials.region}
            onChange={(e) => setAwsCredentials(prev => ({
              ...prev,
              region: e.target.value
            }))}
          />
          <button onClick={() => setShowCredentialsModal(false)}>
            Save Credentials
          </button>
        </div>
      )}

      {isEditing && (
        <div className="content-editor">
          <textarea
            value={editedContent}
            onChange={(e) => setEditedContent(e.target.value)}
            disabled={isLoading}
          />
          <div className="editor-controls">
            <button 
              onClick={handleSave}
              disabled={isLoading}
            >
              {isLoading ? 'Saving...' : 'Save Changes'}
            </button>
            <button 
              onClick={handleCancel}
              disabled={isLoading}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminToolbar;